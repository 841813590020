<template>
  <!-- ======= Header ======= -->
  <header
    id="header"
  >
    <div class="container align-items-center">
      <b-navbar
        toggleable="sm"
      >
        <b-navbar-brand 
          data-cy="logo"
          class="logo "
        >
          <a
            href="/"
            class="glitch"
            data-glitch="this is an art form"
          >
            this is an art form
          </a>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse" />
        <b-collapse
          id="nav-collapse"
          is-nav
        >
          <b-navbar-nav class="ml-auto">
            <b-nav-item
              v-if="!isLoggedIn"
              to="/login"
              data-cy="nav-login"
              right
            >
              Login
            </b-nav-item>
            <b-nav-item
              v-if="isLoggedIn"
              to="/Dashboard"
              right
            >
              Dashboard
            </b-nav-item>
            <b-nav-item
              v-if="isLoggedIn"
              href="#logout"
              right
              @click="onLogout"
            >
              Logout
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </header>
</template>

<script>
import Api from '@/services/Api'
import axios from "axios";

export default {
  name: 'Header',
  data() {
    return {
    }
  },
  computed: {
    isLoggedIn:  function () {
      return !!this.$store.state.user.token
    },
    isVerified:  function () {
      return this.$store.state.user.isVerified
    }
  },
  mounted: function () {

  },
  methods: {
    // TODO: Make this call the logout helper method.
    onLogout() {
      // Remote the token first
      this.$store.dispatch('user/unload')
      sessionStorage.clear()
      // Then try to log out via the api
      Api().post('/logout')
        .then((resp) => {
          axios.defaults.headers.common['Authorization'] = ""
          this.$router.push({path: '/'})
        })
        .catch((error) => {
          // alert(error.data.message)
          this.$router.push({path: '/'})
        });
    },
    onVerify() {
      Api().post('/verify')
        .then((resp) => {
          if (resp.status === 200) {
            alert("Please visit the verification link in an email we've sent you!")
          }
          else {
            // console.log(resp)
            alert("Something went wrong!")
          }
        })
        .catch((error) => {
          if (error.message.includes("401")) {
            alert("Please authorize first.")
            this.$store.dispatch('user/load', {token: null, verified: false})
          } else {
            alert(error)
          }
          // this.$router.push({path: '/'})
        });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>