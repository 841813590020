<template>
  <b-container fluid>
    <h3 class="text-primary">
      your player status symbol: 
      <br>
      GEMICOIN
    </h3>
    <br>
    <img
      src="/img/coin.gif"
      width="200"
    >
    <br>
    <br>
    There are always two sides to every coin, especially within the post capitalist promised land. 
    You easily achive dualistic nirvana as both a consumer and producer, bull and bear.
    <br>
    Your life motto: Let's flip this bitch!!!
    <br>
    <br>
  </b-container>
</template>

<script>
  import Api from "@/services/Api"
  import axios from "axios"

  export default {
    data () {
      return {
        playerLink: 'add a link'
      }
    },
    mounted: function () {
      // nothing to do here 
      // maybe get the user from the api
    },
    methods: {}
  };
</script>