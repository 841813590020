<template>
  <section>
    <div class="containter">
      <b-row
        class="justify-content-md-center"
      >
        <b-col 
          lg="4"
        >          
          <h3>{{ message }}</h3>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import Api from '@/services/Api'

export default {
  name: "Verify",
  data() {
      return {
        message: ""
      }
    },
  mounted() {
      this.verifyUser()
    },
  methods: {
    verifyUser: function () {
      let token = this.$route.params.token
      Api().post('/verify/' + token)
          .then((resp) => {
            if (resp.status === 200) {
              this.message = "Thank you! Your account has been verified."
              let token = this.$store.state.user.token
              if (token){
                this.$store.dispatch('user/load', {token: token, verified: true})
              } else {
                this.$store.dispatch('user/unload')
              }

            }
          })
          .catch((error) => {
            this.message = error
          })
    },

  }
}
</script>

<style scoped>

</style>