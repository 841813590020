<template>
  <span>
    <section
      id="supercrapitalist"
      class="d-flex align-items-center"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center translucent">
            <b-row
              class="justify-content-center"
            >
              <b-col
                class=""
                lg="8"
                md="7"
                sm="6"
              >
                <h3>
                  Enter the secret password:
                </h3>
                <b-form
                  ref="passwordForm"
                  class="needs-validation"
                  :class="{'was-validated': invalid}"
                  data-cy="password-form"
                  novalidate
                  @submit.prevent="passwordSubmit"
                >

                  <b-form-group 
                    id="input-group-password" 
                    label-for="input-password"
                  >
                    <b-form-input
                      id="input-password"
                      v-model="form.password"
                      type="password"
                      placeholder="the secret password"
                      required
                      data-cy="login-password"
                    />
                  </b-form-group>

                  <b-button 
                    type="submit" 
                    block
                    variant="primary"
                    data-cy="password-submit"
                  >
                    Whisper 👂🏿👂🏽👂🏻👂🏼👂🏾
                  </b-button>
                </b-form>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </section>
  </span>
</template>

<script>
  import Api from "@/services/Api";

  export default {
    name: 'SuperCrapitalist',
    data() {
      return {
        form: {}
      }
    },
    methods: {
      submitQuestions(evt) {
        for (let i = 0; i < this.form.length; i++) {
          Api().post('/onboarding_questions', {
              question: this.form[i].question,
              answer: this.form[i].answer
            })
            .then((resp) => {
              // this.$router.push({path: '/dashboard'})
            })
            .catch((error) => {
              console.log(error)
            });
        }
        this.$router.push({path: '/dashboard'})
      }
    }
  }
</script>