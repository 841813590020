<template>
  <span>
    <section
      id="hero"
      class="d-flex align-items-center"
    >
      <PaymentForm />
    </section>
  </span>
</template>

<script>
import PaymentForm from '@/components/PaymentForm.vue'
import Api from '@/services/Api'

export default {
  name: 'Home',
  components: {
    PaymentForm
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
