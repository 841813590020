import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import LoadScript from 'vue-plugin-load-script'
import store from '@/store'
import VueSegmentAnalytics from 'vue-segment-analytics'

// import axios from 'axios'

// const token = store.state.user.token
// if (token) {
//   axios.defaults.headers.common['Authorization'] = token
// }

// Add a request interceptor
// axios.interceptors.request.use(function (config) {
//     const token = store.state.user.token;
//     config.headers.Authorization = token;
//
//     return config;
// });

Vue.config.productionTip = false

Vue.use(VueSegmentAnalytics, {
  id: 'm6pErQxAERjzlYmVwCdniKcEzDeQjtZc',
  router // Optional
})

Vue.use(LoadScript);
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
