<template>
  <b-container fluid>
    <b-row
      class="justify-content-md-center"
    >
      <b-col
        class="translucent"
        lg="6"
        md="8"
      >
        <h4 class="text-primary">
          This is an ART FORM... 
          Details go in...
        </h4>
        <b-form
          id="payment-form"
          ref="paymentForm"
          class="needs-validation"
          :class="{'was-validated': invalid}"
          autocomplete="on"
          data-cy="payment-form"
          novalidate
          @submit.prevent="paymentSubmit"
        >
          <b-form-group
            id="input-group-email"
            label=""
            label-for="payment-email"
          >
            <b-form-input
              id="payment-email"
              ref="email"
              v-model="form.email"
              type="email"
              required
              minlength="6"
              placeholder="Email address"
              data-cy="payment-email"
              autocomplete="email"
            />
          </b-form-group>
          <b-form-group
            id="input-group-password"
            label=""
            label-for="payment-password"
            description="Use a strong password, 8 characters minimum."
          >
            <b-form-input
              id="payment-password"
              ref="payment-password"
              v-model="form.password"
              type="password"
              required
              minlength="8"
              placeholder="Create a passsword"
              data-cy="payment-password"
              autocomplete="new-password"
            />
          </b-form-group>
          <b-form-group
            id="input-group-first-name"
            label=""
            label-for="input-first-name"
          >
            <b-row>
              <b-col md="6">
                <b-form-input
                  id="payment-first-name"
                  ref="first-name"
                  v-model="form.firstName"
                  type="text"
                  required
                  minlength="1"
                  placeholder="First name"
                  data-cy="payment-first-name"
                  autocomplete="given-name"
                />
              </b-col>
              <b-col md="6">
                <b-form-input
                  id="payment-last-name"
                  ref="last-name"
                  v-model="form.lastName"
                  type="text"
                  required
                  minlength="1"
                  placeholder="Last name"
                  data-cy="payment-last-name"
                  autocomplete="family-name"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            id="input-group-amount"
            label=""
            label-for="input-first-name"
          >
            <b-form-select
              v-model="contributionType"
              required
              :options="contributionTypes"
            />
          </b-form-group>
          <b-form-group
            id="input-group-amount"
            label=""
            label-for="input-first-name"
          >
            <b-form-select
              v-model="form.priceId"
              required
              :options="contributionAmounts"
            />
          </b-form-group>
          <b-form-group
            id="input-group-payment"
            label=""
            label-for="input-first-name"
          >
            <div
              ref="card"
              class="form-control"
            >
              CC
            </div>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox-group 
              v-model="form.checked"
              required
              data-cy="payment-tos-checkbox-group"
            >
              <b-form-checkbox 
                name="terms-of-service"
                value="tos"
              >
                I agree to the <a
                  href="#"
                  target="_blank"
                >terms of service</a>.
              </b-form-checkbox>
              <b-form-invalid-feedback>
                Please select review and agree to the terms of service.
              </b-form-invalid-feedback>
            </b-form-checkbox-group>
          </b-form-group>
          <b-button 
            id="payment-submit"
            type="submit" 
            block
            variant="primary"
            size="lg"
            data-cy="payment-submit"
            :disabled="lockSubmit"
            @click.prevent="validateTransaction"
          >
            👉 Art Comes Out 👈
          </b-button>
        </b-form>
        <!-- 
        <b-card 
          class="mt-3" 
          header="Form Data Result"
        >
          <pre class="m-0">{{ form }}</pre>
          <pre class="m-0">{{ contributionAmounts }}</pre>
        </b-card>
 -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Api from "@/services/Api"
  import axios from "axios"

  export default {
    data () {
      return {
        stripe: undefined,
        card: undefined,
        cardComplete: false,
        lockSubmit: false,
        invalid: false,
        lastRecurringId: null,
        lastOnetimeId: null,
        contributionTypes: [
          {value: null, text: "Select your contribution type...", disabled: true},
          {value: 'one-time', text: "One Time Contribution"},
          {value: 'subscription', text: "Monthly Subscription"},
        ],
        contributionType: 'one-time',
        contributionAmounts: [
          {value: null, text: "Select your contribution amount...", disabled: true},
        ],
        recurringAmounts: [
          {value: null, text: "Select your contribution amount...", disabled: true},
        ],
        onetimeAmounts: [
          {value: null, text: "Select your contribution amount...", disabled: true},
        ],
        form: {}
      }
    },
    watch: {
      contributionType(type) {
        if (type == 'subscription') {
          this.lastOnetimeId = this.form.priceId
          this.contributionAmounts = this.recurringAmounts
          this.form.priceId = this.lastRecurringId
        } else {
          this.lastRecurringId = this.form.priceId
          this.contributionAmounts = this.onetimeAmounts
          this.form.priceId = this.lastOnetimeId
        }
      }
    },
    mounted: function () {
      // Setup some parts of the form dynamically
      Api().get('/app/config')
        .then(response => {
          if(response.status == 200) {
            // Setup Stripe with the public key
            this.setupStripe(response.data.stripe_public_key)
            // Configure the Payment Options
            this.setupPaymentOptions(response.data.pricing_options)
          } else {
            alert("Error loading: Please try again later :(")
          }
      })
        .catch((err) => {
          alert("Error: " + err.message)
      });
    },

    methods: {
      setupStripe(stripeKey) {
        // Setup the Stripe Element
        this.stripe = Stripe(stripeKey);
        this.card = this.stripe.elements({
          fonts: [{
            family:'Major Mono Display',
            src: 'https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap',
          }]
        }).create('card', {
          style: { base: { fontSize: '16px'}},
          classes: {
              base: 'stripe-element-base',
              complete: 'is-valid',
              invalid: 'is-invalid'
          },
        });
        this.card.mount(this.$refs.card);
        this.card.on('change', this.handleCardEvents)
      },
      handleCardEvents(event) {
        // Make the card input valid when completed
        this.cardComplete = event.complete // Needed for form validation
      },
      setupPaymentOptions(options) {
            let option = null
            for (var el of options.donation) {
              if (el.sold_out) {
                option = { value: el.id, text: el.nickname + " (Sold out)", disabled: true}
              } else {
                option = { value: el.id, text: el.nickname}
              }
              this.onetimeAmounts.push(option)
              if (el.unit_amount == 5500) {
                this.form.priceId = el.id
                this.lastOnetimeId = el.id
              }
            }
            for (var el of options.membership) {
              if (el.sold_out) {
                option = { value: el.id, text: el.nickname + " (Sold out)", disabled: true}
              } else {
                option = { value: el.id, text: el.nickname}
              }
              this.recurringAmounts.push(option)
              if (el.unit_amount == 2100) {
                this.lastRecurringId = el.id
              }
            }
            this.contributionAmounts = this.onetimeAmounts
      },
      validateTransaction() {
        self = this  // avoid scope issues
        this.lockSubmit = true
        // Check if the form is completed sufficently
        if (!this.$refs.paymentForm.checkValidity() || !this.cardComplete) {
            this.invalid = true
            this.lockSubmit = false
        } else {
          this.stripe.createToken(this.card).then( function(result) {
            if (result.error) {
              alert(result.error.message)
              this.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
              this.lockSubmit=false
              return;
            }
            else{ 
              self.processTransaction(result.token.id)
            }
          })
          .catch((err) => {
            alert("error: "+err.message)
            this.lockSubmit = false
          });
        }
      },
      processTransaction(transactionToken){
        // Post the data to the app API
        Api().post('/users', {
          email: this.form.email, 
          password: this.form.password, 
          first_name: this.form.firstName, 
          last_name: this.form.lastName,
          currency: "USD",
          transaction_token: transactionToken,
          price_id: this.form.priceId
        })
          .then(response => {
            console.log(response)
            console.log("Payment Status: "+ response.status)
            if(response.status == 201){
              // todo also login the user
              self.lockSubmit = false
              this.$router.push('/welcome')
            } else {
              // todo: handle the different status codes.
              throw new Error("Payment failed.")
            }
        })
          .catch((err) => {
            alert("error: "+err.message)
            self.lockSubmit=false
        });
      }
    }
  };
</script>