<template>
  <span>
    <section
      id="hero"
      class="d-flex align-items-center smiles"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center translucent welcome">
            <img
              id="smile"
              src="/img/smile.gif"
            >
            <h1>
              WElCoME cyBer TRAVELER!
            </h1>
            <h3>
              Looks like you know
              <br>
              this world is pay to play
            </h3>
            <br><br>
            <h5>
              What, exactly, did you pay for??? It's up to you to find out...
            </h5>
            <br>
            <p>
              It's dangerous to go alone!
              Take this.
              <img 
                src="/img/checkbook.png"
                width="40"
              >
            </p>
          </div>
        </div>
      </div>
    </section>

    <section
      id="player-status"
      class="d-flex align-items-center"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center translucent">
            <br>
            <PlayerStatus />
          </div>
        </div>
      </div>
    </section>


    <section
      id="shares-link"
      class="d-flex align-items-center"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center translucent">
            <b-container fluid>
              <br>
              <h3 class="text-primary">
                Your shares link:
              </h3>
              <b-form-input
                id="shares-link-link"
                ref="sharesLink"
                v-model="sharesLink"
                type="text"
                data-cy="player-link"
              />
              <br>

              how shares work:
              <br>
              <ol>
                <li>
                  Anyone who clicks your link generates more shares for you.
                </li>
                <li>
                  When they register they <i>borrow</i> your shares.
                </li>
                <li>
                  You earn interest. <u>Until. You click their link.</u>
                </li>
                <li>
                  Anyone else who registers via your link also borrows your shares.
                </li>
                <li>
                  You can borrow the same shares to multiple people.
                </li>
                <li>
                  <strike>We require you reserve 10% of shares on hand for all that you borrow out.</strike>
                </li>
                <li>
                  No wait. We don't require that you keep a reserve anymore.
                </li>
              </ol>
              <br>
              <br>
              Shares aren't actually worth anything, but you want to get more of them. Don't you.
            </b-container>
          </div>
        </div>
      </div>
    </section>

    <section
      id="crapital"
      class="d-flex align-items-center"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center translucent">
            <br>
            <h4>
              private beta: <br>
              PlaY Super CRAPitalist!<br>
              <router-link 
                to="/supercrapitalist"
              >
                <img
                  id="crap"
                  src="/img/crap.gif"
                ><br>
                The game where you live in a world of <br>
                SUPER ABUNDANT CRAPital<br>
                use your CRAPital to make more CRAPital!!!
              </router-link>
            </h4>
          </div>
        </div>
      </div>
    </section>

    <section
      id="shipping"
      class="d-flex align-items-center"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center translucent">
            <br>
            <ShippingForm />
          </div>
        </div>
      </div>
    </section>

  </span>
</template>

<script>
  import PlayerStatus from '@/components/PlayerStatus.vue'
  import ShippingForm from '@/components/ShippingForm.vue'
  import Api from "@/services/Api";

  export default {
    name: 'Welcome',
    components: {
      PlayerStatus,
      ShippingForm,
    },
    data() {
      return {
        sharesLink: 'https://www.thisisanartform/invite/kjsdf6723kj5hsdf7'
      }
    },
    methods: {}
  }
</script>