<template>
  <footer id="footer">
    <div class="container py-4">
      <div 
        class="copyright"
        data-cy="copyright"
      >
        &copy; Copyright <strong><span>You are an Art Form 2020</span></strong>. All Rights Reserved.
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
</style>