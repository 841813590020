<template>
  <section>
    <div class="containter">
      <b-row
        class="justify-content-md-center"
      >
        <b-col 
          lg="4"
        >          
          <h3>Thank you. You are on the waitlist.</h3>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {}
    },
    methods: {}
  }
</script>