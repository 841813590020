<template>
  <b-container fluid>
    <h3 class="text-primary">
      Fill this form out too.
    </h3>
    <h5>
      Yeah, we'll be shipping you... something...
    </h5>
    <b-form
      id="shipping-form"
      ref="shippingForm"
      class="needs-validation"
      :class="{'was-validated': invalid}"
      autocomplete="on"
      data-cy="shipping-form"
      novalidate
      @submit.prevent="shippingSubmit"
    >
      <b-form-group
        id="input-group-address-name"
        label=""
        label-for="address-name"
      >
        <b-form-input
          id="address-name"
          ref="addressName"
          v-model="form.addressName"
          type="text"
          required
          minlength="2"
          placeholder="Your Name"
          data-cy="address-name"
          autocomplete="name"
        />
      </b-form-group>
      <b-form-group
        id="input-group-address-line1"
        label=""
        label-for="address-line1"
      >
        <b-form-input
          id="address-line1"
          ref="addressLine1"
          v-model="form.addressLine1"
          type="text"
          required
          minlength="6"
          placeholder="Street address"
          data-cy="address-line1"
          autocomplete="address-line1"
        />
      </b-form-group>
      <b-form-group
        id="input-group-address-line2"
        label=""
        label-for="address-line2"
      >
        <b-form-input
          id="address-line2"
          ref="addressLine2"
          v-model="form.addressLine2"
          type="text"
          minlength="6"
          placeholder="Apt / Address line 2"
          data-cy="address-line2"
          autocomplete="address-line2"
        />
      </b-form-group>
      <b-form-group
        id="input-group-address-city"
        label=""
        label-for="address-city"
      >
        <b-form-input
          id="address-city"
          ref="address-city"
          v-model="form.addressCity"
          type="text"
          minlength="6"
          placeholder="City"
          data-cy="address-city"
          autocomplete="address-city"
        />
      </b-form-group>
      <b-form-group
        id="input-group-address-level1"
        label=""
        label-for="address-level2"
      >
        <b-form-input
          id="address-level1"
          ref="addressLevel1"
          v-model="form.addressLevel1"
          type="text"
          minlength="6"
          placeholder="State / Region"
          data-cy="address-level1"
          autocomplete="address-level1"
        />
      </b-form-group>
      <b-form-group
        id="input-group-address-postal-code"
        label=""
        label-for="address-postal-code"
      >
        <b-form-input
          id="address-postal-code"
          ref="addressPostalCode"
          v-model="form.addressPostalCode"
          type="text"
          minlength="6"
          placeholder="Postal Code"
          data-cy="address-postal-code"
          autocomplete="address-postal-code"
        />
      </b-form-group>
      <b-button 
        id="payment-submit"
        type="submit" 
        block
        variant="primary"
        size="lg"
        data-cy="payment-submit"
        :disabled="lockSubmit"
        @click.prevent="validateTransaction"
      >
        🚢 Ship It 🚢
      </b-button>
    </b-form>
      
    <!--     <b-card 
      class="mt-3" 
      header="Form Data Result"
    >
      <pre class="m-0">{{ form }}</pre>
    </b-card> -->
  </b-container>
</template>

<script>
  import Api from "@/services/Api"
  import axios from "axios"

  export default {
    data () {
      return {
        form: {}
      }
    },
    mounted: function () {
      // nothing to do here 
      // maybe get the user from the api
    },
    methods: {
      validate: function () {
        self = this  // avoid scope issues
        this.lockSubmit = true
        // Check if the form is completed sufficently
        if (!this.$refs.shippingForm.checkValidity()) {
            this.invalid = true
            this.lockSubmit = false
        } else {
          this.stripe.createToken(this.card).then( function(result) {
            if (result.error) {
              alert(result.error.message)
              this.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
              this.lockSubmit=false
              return;
            }
            else{ 
              self.processTransaction(result.token.id)
            }
          })
          .catch((err) => {
            alert("error: "+err.message)
            this.lockSubmit = false
          });
        }
      },
      save: function() {
        // Post the data to the app API
        Api().post('/users', {
          email: this.form.email, 
          password: this.form.password, 
          first_name: this.form.firstName, 
          last_name: this.form.lastName,
          currency: "USD",
          transaction_token: transactionToken,
          price_id: this.form.priceId
        })
          .then(response => {
            console.log(response)
            console.log("Payment Status: "+ response.status)
            if(response.status == 201){
              // todo also login the user
              self.lockSubmit = false
              this.$router.push('/welcome')
            } else {
              // todo: handle the different status codes.
              throw new Error("Payment failed.")
            }
        })
          .catch((err) => {
            alert("error: "+err.message)
            self.lockSubmit=false
        });
      }
    }
  };
</script>