<template>
  <section>
    <div class="containter">
      <b-row
        class="justify-content-md-center"
      >
        <b-col 
          lg="4"
        >       
          <h3>Just a few questions:</h3>
          <b-form 
            v-if="show"
            @submit.prevent="submitOnboardingQuestions"
          >
            <div
              v-for="item in form"
              :key="item.question"
            >
              <b-form-group
                :label="item.question"
                description=""
              >
                <b-form-input
                  v-model="item.answer"
                  required
                />
              </b-form-group>
            </div>
            <b-button 
              type="submit" 
              variant="primary"
            >
              Submit
            </b-button>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
  import Api from "@/services/Api";
  export default {
    data() {
      return {
        show: true,
        form: [{
          question: "Why so serious?",
          answer: '',
        },{
          question: "What does Marsellus Wallace look like?",
          answer: '',
        },{          
          question: "Who killed Laura Palmer?",
          answer: '',
        }],
      }
    },
    methods: {
      submitOnboardingQuestions(evt) {
        for (let i = 0; i < this.form.length; i++) {
          Api().post('/onboarding_questions', {
              question: this.form[i].question,
              answer: this.form[i].answer
            })
            .then((resp) => {
              // this.$router.push({path: '/dashboard'})
            })
            .catch((error) => {
              console.log(error)
            });
        }
        this.$router.push({path: '/dashboard'})
      }
    }
  }
</script>